import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import async from 'async';
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-light-accordion/demo/css/index.css';
import UploadImage from '../../helpers/uploadImage';
import UploadImageGallery from '../../helpers/uploadImageGallery';
import GalleriesServices from '../../services/galleries.services';
import LanguagesServices from '../../services/languages.services';
import CategoriesServices from '../../services/categories.services';
import AuthorsServices from '../../services/authors.services';
import TagsServices from '../../services/tags.services';
import Helpers from '../../helpers/helpers';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import LocalStorageServices from '../../services/localStorage.services';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const moduleName = 'galleries';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;

var languageDefault = '';
const FormGalleries = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    /* const [activeTabImage, setActiveTabImage] = useState(0); */
    const [startDate, setStartDate] = useState(new Date());
    const [image, setImage] = useState(null);
    const [images, setImages] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [indexDelete, setIndexDelete] = useState(null);
    const [imageInput, setImageInput] = useState('');
    const { register, handleSubmit, errors, setValue, triggerValidation } = useForm();
    const currentUser = LocalStorageServices.getCurrentUser();
    const styleSelect = {
        searchBox: {
            border: "1px solid #374558",
            backgroundColor: "#293240",
        },
        inputField: {
            color: 'rgba(255, 255, 255, 0.85)'
        },
        optionContainer: {
            backgroundColor: '#293240'
        },
        chips: {
            backgroundColor: '#4466f2',
            fontSize: '14px'
        }
    }
    useEffect(() => {
        //Set valores update
        const setValuesForm = (data, languages) => {
            setValue('categoria_principal', data.categoria_principal._id);
            setValue('estatus', data.estatus);
            setStartDate(moment(data.fecha_publicacion).toDate());
            setImageInput(data.imagen_portada);
            languages.forEach(function (value, index) {
                let languageForm = data.idiomas.find(language => language.id_idioma === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].titulo`, languageForm.titulo);
                    setValue(`idiomas[${index}].url`, languageForm.url);
                    setValue(`idiomas[${index}].alt`, languageForm.alt);
                    setValue(`idiomas[${index}].descripcion`, languageForm.descripcion);
                    setValue(`idiomas[${index}].seo.titulo`, languageForm.seo.titulo);
                    setValue(`idiomas[${index}].seo.titulo_link`, languageForm.seo.titulo_link);
                    setValue(`idiomas[${index}].seo.palabras_clave`, languageForm.seo.palabras_clave);
                    setValue(`idiomas[${index}].seo.descripcion`, languageForm.seo.descripcion);
                }
            });
            //set categorias extras
            let categories = [];
            data.categorias_extras.forEach(category => {
                let item = {
                    "_id": category._id,
                    "nombre": ''
                }
                let languageCategory = category.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                item.nombre = languageCategory.nombre;
                categories.push(item);
            });
            setSelectedCategories(categories);
            //set etiquetas
            let tags = [];
            data.etiquetas.forEach(tag => {
                let item = {
                    "_id": tag._id,
                    "nombre": ''
                }
                let languageTag = tag.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                item.nombre = languageTag.nombre;
                tags.push(item);
            });
            setSelectedTags(tags);
            //Set imagenes extras
            let imagesExtras = []
            data.imagenes.forEach(image => {
                let imageItem = {
                    imagePath: image.imagen,
                    order: image.orden,
                    status: image.estatus,
                };
                imagesExtras.push(imageItem);
            });
            setImages(imagesExtras);
        }
        async function fetchData() {
            setLoadData(true);
            async.series({
                languages: function (cb) {
                    let params = {
                        limit: 10000000,
                        order: -1,
                        sort: "fecha_creacion"
                    }
                    LanguagesServices.get(params)
                        .then(response => {
                            cb(null, response.data.data);
                        })
                        .catch(error => {
                            toast.error("Oops ha ocurrido un error al obtener los idiomas!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            console.log(error);
                            cb(error, null);
                        });
                }
            }, function (error, results) {
                if (error === null) {
                    setLanguages(results.languages);
                    var _languages = results.languages;
                    languageDefault = results.languages.find(language => language.idioma_defecto === true);
                    async.parallel({
                        categories: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            CategoriesServices.get(params)
                                .then(response => {
                                    let categories = [];
                                    response.data.data.forEach(category => {
                                        let item = {
                                            "_id": category._id,
                                            "nombre": ''
                                        }
                                        let languageCategory = category.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                                        item.nombre = languageCategory.nombre;
                                        categories.push(item);
                                    });
                                    cb(null, categories);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener las categorías!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        },
                        authors: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            AuthorsServices.get(params)
                                .then(response => {
                                    cb(null, response.data.data);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener los autores!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        },
                        tags: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            TagsServices.get(params)
                                .then(response => {
                                    let tags = [];
                                    response.data.data.forEach(tag => {
                                        let item = {
                                            "_id": tag._id,
                                            "nombre": ''
                                        }
                                        let languageTag = tag.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                                        item.nombre = languageTag.nombre;
                                        tags.push(item);
                                    });
                                    cb(null, tags);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener las etiquetas!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        }
                    },
                        async function (error, results) {
                            if (error === null) {
                                setCategories(results.categories);
                                setTags(results.tags);
                                if (props.match.params.id !== 'new') {
                                    setTitleForm('Editar');
                                    await GalleriesServices.getOne(props.match.params.id)
                                        .then(response => {
                                            setValuesForm(response.data, _languages);
                                            setLoadData(false);
                                        })
                                        .catch(error => {
                                            setLoadData(false);
                                            toast.error("Oops ha ocurrido un error!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                            console.log(error);
                                        })
                                }
                            }
                            setLoadData(false);
                        });
                }
            });
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                // console.log(data);
                // return;
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        data.categorias_extras = [];
        data.etiquetas = [];
        data.imagenes = [];
        selectedCategories.forEach((value) => {
            data.categorias_extras.push(value._id);
        });
        selectedTags.forEach((value) => {
            data.etiquetas.push(value._id);
        });
        images.forEach((value) => {
            let itemImage = {
                imagen: value.imagePath !== null ? value.imagePath : '/no-imagen.jpg',
                estatus: value.status,
                orden: value.order
            };
            data.imagenes.push(itemImage);
        });
        data.fecha_publicacion = startDate;
        data.imagen_portada = image !== null ? image : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        try {
            GalleriesServices.create(data)
                .then(response => {
                    toast.success("Categoría guardada correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/portal/galleries");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    const update = (data) => {
        if (image !== null) {
            data.imagen_portada = image
        }
        data.categorias_extras = [];
        data.etiquetas = [];
        data.imagenes = [];
        selectedCategories.forEach((value) => {
            data.categorias_extras.push(value._id);
        });
        selectedTags.forEach((value) => {
            data.etiquetas.push(value._id);
        });
        images.forEach((value) => {
            let itemImage = {
                imagen: value.imagePath !== null ? value.imagePath : '/no-imagen.jpg',
                estatus: value.status,
                orden: value.order
            };
            data.imagenes.push(itemImage);
        });
        data.fecha_publicacion = startDate;

        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }

        GalleriesServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Categoría actualizada correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/galleries");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }

    const onSelectCategory = (selectedList, selectedItem) => {
        setSelectedCategories(selectedList);
    }

    const onRemoveCategory = (selectedList, removedItem) => {
        setSelectedCategories(selectedList);
    }

    const onSelectTag = (selectedList, selectedItem) => {
        setSelectedTags(selectedList);
    }

    const onRemoveTag = (selectedList, removedItem) => {
        setSelectedTags(selectedList);
    }
    const handleChange = date => {
        setStartDate(date)
    }
    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = Helpers.formatUrl(e.target.value);
    }
    const handleImage = (image) => {
        setImage(image)
    }
    const addImage = () => {
        let imageItem = {
            imagePath: null,
            order: 1,
            status: 'A',
        };
        setImages([...images, imageItem]);
    }
    const onChandaDataImage = (index, value, input) => {
        var array = [...images];
        switch (input) {
            case 'imagePath':
                array[index].imagePath = value;
                break;
            case 'order':
                array[index].order = value
                break;
            case 'status':
                array[index].status = value
                break;
            default:
        }
        setImages(array);
    }
    const removeImage = () => {
        var array = [...images];
        array.splice(indexDelete, 1);
        setImages(array);
        setShowAlert(false);
        setIndexDelete(null);
    }
    const hideAlert = () => {
        setIndexDelete(null);
        setShowAlert(false);
    }

    const handleImageExtra = (image, index) => {
        console.log(image, index);
        var array = [...images];
        array[index].imagePath = image;
        setImages(array);
    }

    const validateAllInputs = async () => {
        const result = await triggerValidation();
        console.log(result);
        if (!result) {
            toast.error("Verifique que haya llenado los campos obligatorios en los idiomas!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    return (
        <Fragment>
            <SweetAlert
                show={showAlert}
                showCancel
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                confirmBtnText="Eliminar"
                cancelBtnText="Cancelar"
                title="¿Estás seguro?"
                onConfirm={() => removeImage()}
                onCancel={hideAlert}
            >
                El cambio se efecturá al guardar la galería!
            </SweetAlert>
            <Breadcrumb parent="Galerías" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Galería</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className='form-row'>
                                        <div className="col-md-12 mb-3">
                                            <UploadImage id={props.match.params.id} imageInput={imageInput} setImage={handleImage} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label>Categoría Principal</label><br />
                                            <select className="form-control" name="categoria_principal" ref={register({ required: true })}>
                                                <option value="">Seleccione una categoría</option>
                                                {categories.map((value, index) => {
                                                    return <option value={value._id} key={index}>{value.nombre}</option>
                                                })}
                                            </select>
                                            <span>{errors.categoria_principal && 'Categoría principal es requerido'}</span>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Fecha de publicación</label>
                                            <DatePicker className="form-control" showPopperArrow={false} selected={startDate} showTimeSelect onChange={handleChange} dateFormat="dd/MM/yyyy hh:mm aa" />
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col-md-6 mb-3'>
                                            <label>Otras categorías</label>
                                            <Multiselect
                                                className="form-control"
                                                options={categories} // Options to display in the dropdown
                                                selectedValues={selectedCategories} // Preselected value to persist in dropdown
                                                onSelect={onSelectCategory} // Function will trigger on select event
                                                onRemove={onRemoveCategory} // Function will trigger on remove event
                                                displayValue="nombre" // Property name to display in the dropdown options
                                                placeholder='Categorías'
                                                style={styleSelect}
                                                emptyRecordMsg='No hay más categorías'
                                                id='categories'
                                            />

                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Etiquetas</label>
                                            <Multiselect
                                                className="form-control"
                                                options={tags} // Options to display in the dropdown
                                                selectedValues={selectedTags} // Preselected value to persist in dropdown
                                                onSelect={onSelectTag} // Function will trigger on select event
                                                onRemove={onRemoveTag} // Function will trigger on remove event
                                                displayValue="nombre" // Property name to display in the dropdown options
                                                placeholder='Etiquetas'
                                                style={styleSelect}
                                                emptyRecordMsg='No hay más etiquetas'
                                                id='tags'
                                            />

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className="col-md-6 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="tab-languages" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label>Título</label>
                                                        <input className="form-control" name={`idiomas[${index}].titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].titulo`] && 'titulo es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label>URL</label>
                                                        <input className="form-control" name={`idiomas[${index}].url`} type="text" placeholder="URL" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                                        <span>{errors[`idiomas[${index}].url`] && 'URL es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <label>Alt</label>
                                                        <input className="form-control" name={`idiomas[${index}].alt`} type="text" placeholder="Alt" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].alt`] && 'Alt es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">

                                                    <div className="col-md-12 mb-3">
                                                        <label>Descripción</label>
                                                        <textarea className="form-control" name={`idiomas[${index}].descripcion`} ref={register({ required: true })} rows="3"></textarea>
                                                        <span>{errors[`idiomas[${index}].descripción`] && 'Descripción es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>SEO</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo Link</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo_link`} type="text" placeholder="Título Link" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo_link`] && 'Título link es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Palabras Clave</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.palabras_clave`} type="text" placeholder="Palabras clave" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.palabras_clave`] && 'Palabras clave es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Descripción</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.descripcion`} type="text" placeholder="Descripción" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.descripcion`] && 'Descripción es requerido'}</span>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>
                                    {images.length > 0 &&
                                        <div className='row'>
                                            <div className='col-md-12 mb-3'>
                                                <Accordion allowZeroExpanded={true}>
                                                    {images.map((image, index) => {
                                                        return <AccordionItem key={index} uuid={index}>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                    Imagen extra {index + 1}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className='form-row'>
                                                                    <div className="col-md-5 mb-3">
                                                                        <UploadImageGallery id={index} imageInput={image.imagePath} setImage={(image) => { handleImageExtra(image, index) }} />
                                                                    </div>
                                                                    <div className="col-md-2 mb-3">
                                                                        <label>Orden</label>
                                                                        <input className="form-control" type="text" placeholder="Orden" value={image.order} onChange={(e) => { e.preventDefault(); const value = e.target.value; onChandaDataImage(index, value, 'order') }} />
                                                                        <span>{errors[`imagenes[${index}].orden`] && 'Orden es requerido'}</span>
                                                                    </div>
                                                                    <div className="col-md-5 mb-3">
                                                                        <label>Estatus</label>
                                                                        <select className="form-control" value={image.status} onChange={(e) => { e.preventDefault(); const value = e.target.value; onChandaDataImage(index, value, 'status') }}>
                                                                            <option value="">Estatus</option>
                                                                            <option value="A">Activo</option>
                                                                            <option value="I">Inactivo</option>
                                                                        </select>
                                                                        <span>{errors[`imagenes[${index}].estatus`] && 'Estatus es requerido'}</span>
                                                                    </div>
                                                                </div>
                                                                {/* <Nav tabs className="border-tab-primary">
                                                                    {languages.map((value, index) => {
                                                                        return <NavItem className="nav nav-tabs" id="tab-languages-image-extra" role="tablist" key={index}>
                                                                            <NavLink className={activeTabImage === index ? 'active' : ''} onClick={() => setActiveTabImage(index)}>
                                                                                {value.descripcion}
                                                                            </NavLink>
                                                                        </NavItem>
                                                                    })}
                                                                </Nav>
                                                                <TabContent activeTab={activeTabImage}>
                                                                    {languages.map((value, index) => {
                                                                        return <TabPane tabId={index} key={index} className='m-t-15'>
                                                                            <div className="form-row">
                                                                                idioma
                                                                            </div>
                                                                        </TabPane>
                                                                    })}
                                                                </TabContent> */}
                                                                <div className='row'>
                                                                    <div className='col-md-12 text-right'>
                                                                        <button className="btn btn-pill btn-danger btn-sm" onClick={(e) => { e.preventDefault(); setIndexDelete(index); setShowAlert(true) }}><i className="fa fa-trash-o"></i>  Eliminar</button>
                                                                    </div>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    })}
                                                </Accordion>
                                            </div>
                                        </div>
                                    }
                                    <div className='row'>
                                        <div className='col-md-12 text-right'>
                                            <button className="btn btn-pill btn-light btn-sm" onClick={(e) => { e.preventDefault(); addImage() }}> <i className="fa fa-plus-circle"></i> Agregar Imagen</button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button onClick={async () => { validateAllInputs() }} className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormGalleries;