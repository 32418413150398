import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, Alert } from 'reactstrap';
import LanguagesServices from '../../services/languages.services';
import Pagination from "react-reactstrap-pagination";
const urlPage = process.env.PUBLIC_URL + '/languages';

class ListLanguages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: [],
            loadData: true,
            currentPage: 1,
            pageSize: 5,
            totalRecords: 0
        }
    }
    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    }

    changePageSize = (event) => {
        this.setState({ currentPage: 1 });
        this.setState({ pageSize: parseInt(event.target.value) });
    }
    componentDidMount() {
        let params = {
            page: this.state.currentPage,
            limit: this.state.pageSize
        }
        this.setState({ loadData: true });
        LanguagesServices.get(params)
            .then(response => {
                this.setState({ totalRecords: parseInt(response.data.pagination.total_records) });
                this.setState({ languages: response.data.data })
                this.setState({ loadData: false });
            })
            .catch(error => {
                this.setState({ loadData: false });
                toast.error("Oops ha ocurrido un error!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                console.log(error);
            })
    }
    render() {
        var { loadData, languages, pageSize, currentPage, totalRecords } = this.state;
        return (
            <Fragment>
                <Breadcrumb parent="Idiomas" title="Lista" />
                <div className="container-fluid">
                    <div className='row m-b-10'>
                        <div className="offset-sm-6 col-sm-6 text-right">
                            <Link to={`${urlPage}/new`}>
                                <button className="btn btn-primary" type="button"><i className="fa fa-plus-circle"></i> Agregar</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Idiomas</h5>
                                </div>
                                {loadData === true ? <Progress animated color="info" value={100} className='sm-progress-bar' /> : ''}
                                {languages.length > 0 ?
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Descripción</th>
                                                        <th scope="col">Código</th>
                                                        <th scope="col">Idioma por defecto</th>
                                                        <th scope="col">Estatus</th>
                                                        <th scope="col">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {languages.map((value, index) => {
                                                        return <tr key={index}>
                                                            <td>{value.descripcion}</td>
                                                            <td>{value.codigo}</td>
                                                            <td>{value.idioma_defecto === true ? 'SI' : 'NO'}</td>
                                                            <td>{value.estatus === 'A' ? 'Activo' : 'Inactivo'}</td>
                                                            <td>
                                                                <div className='row'>
                                                                    <div className='col-sm-3 text-center'>
                                                                        <Link className='h5' to={`${urlPage}/${value._id}`}>
                                                                            <i className="txt-success fa fa-edit"></i>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='card-footer'>
                                            <div className='row'>
                                                <div className="col-sm-8">
                                                    <div className='row'>
                                                        <div className="col-sm-6 offset-sm-4 text-right">
                                                            Resultados por página:
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <select className="form-control digits" defaultValue={pageSize} onChange={this.changePageSize}>
                                                                <option value={5}>5</option>
                                                                <option value={10}>10</option>
                                                                <option value={15}>15</option>
                                                                <option value={1000000}>Todos</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4 text-right">
                                                    <Pagination defaultActivePage={currentPage} className='justify-content-end' totalItems={totalRecords} pageSize={pageSize} onSelect={this.handlePageChange} maxPaginationNumbers={3} firstPageText='<' previousPageText='<<' nextPageText='>' lastPageText='>>' />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="card-body" style={(loadData === false && languages.length === 0) ? { display: 'block' } : { display: 'none' }}>
                                        <Alert color="info" className="dark text-center">
                                            <p className="h1">
                                                Sin datos almacenados
                                            </p>
                                        </Alert>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default ListLanguages;