const Helpers = {
    //Valida si un objeto esta vacio
    isEmpty: (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    },
    setQueryParams: (obj) => {
        let url = '?';
        if (obj.hasOwnProperty('sort')) {
            url = url + '_sort=' + obj.sort + '&'
        }
        if (obj.hasOwnProperty('order')) {
            url = url + '_order=' + obj.order + '&'
        }
        if (obj.hasOwnProperty('page')) {
            url = url + '_page=' + obj.page + '&'
        }
        if (obj.hasOwnProperty('limit')) {
            url = url + '_limit=' + obj.limit + '&'
        }
        url = url.slice(0, -1);
        return url
    },
    formatUrl: (value) => {
        let newValue = '';
        newValue = value.replace(/ /g, "-").toLowerCase();
        return newValue;
    }
}

export default Helpers;