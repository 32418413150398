import React, { useState } from 'react';
import Breadcrumb from '../common/breadcrumb';

import manual from '../../documents/manual_usuario.pdf';

import { Document, Page } from "react-pdf/dist/umd/entry.webpack";


const ViewManual = () => {

    const [numPages, setNumPages] = useState(null);


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <>
            <Breadcrumb parent="Manual" title={'Manual De Usuario'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-8 offset-2">
                        <Document
                            file={manual}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {
                                Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                        />
                                    ),
                                )
                            }
                        </Document>
                    </div>
                </div>
            </div>
        </>
    )
}


export default ViewManual;