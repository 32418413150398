import LocalStorageServices from '../services/localStorage.services';
import AuthServices from '../services/auth.services';
const Token = {
    validate: async () => {
        var dataToken = LocalStorageServices.getDataToken();
        const time = dataToken.create;
        const now = new Date().getTime();
        const expire = typeof dataToken.expira !== 'undefined' ? dataToken.expira : 0;
        var sec_diff = (now - time) / 1000;
        const remainingTime = expire - sec_diff;
        if (remainingTime < 60) {
            // send valid token
            let data = { token: dataToken.rft }
            await AuthServices.refreshToken(data)
                .then((response) => {
                    if (typeof response.data.jwt !== 'undefined') {
                        dataToken.jwt = response.data.jwt;
                        dataToken.expira = response.data.expira;
                        LocalStorageServices.setDataToken(dataToken);
                    } else {
                        LocalStorageServices.deleteAllData();
                        window.location.href = '/login';
                    }
                })
                .catch((error) => {
                    console.log('validate token', error);
                    if (error.response.status === 404) {
                        window.location.href = '/login';
                    }

                })
        }
        return;
    }
}

export default Token;