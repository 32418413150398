import axios from 'axios';
import { config } from '../config/config'
const API = axios.create({
    baseURL: config.apiMedias.url
});

const MediasServices = {
    upload: async (data) => {
        const results = await API.post("api/medias/upload", data);
        return results;
    },
    /* delete: async (pathImage) => {
        const results = await API.delete("api/medias/upload?pathImage=" +  pathImage);
        return results;
    }, */
}

export default MediasServices;