import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Progress, Alert } from 'reactstrap';
//import ImgThumbor from '../../helpers/imgThumbor';
import { toast } from 'react-toastify';
import ConfigsServices from '../../services/configs.services';
const urlPage = process.env.PUBLIC_URL + '/portal/configs';



const ListConfigs = () => {
    const [configs, setConfigs] = useState([]);
    const [loadData, setLoadData] = useState(true);


   
    useEffect(() => {
        async function fetchData() {
            setLoadData(true);

            let paramsConfigs = {
                page: 1,
                limit: 5
            }
            setLoadData(true);
            ConfigsServices.get(paramsConfigs)
                .then(response => {
                    let configs = [];
                    response.data.data.forEach(_configs => {
                        let item = {
                            "_id": _configs._id,
                            "email": _configs.email,
                            "estatus": _configs.estatus,
                        }
                        configs.push(item);
                    });
                    setConfigs(configs);
                    setLoadData(false);
                })
                .catch(error => {
                    setLoadData(false);
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                });

        }
        fetchData();
        return () => {
            // clean up
        };
    }, []);
    return (
        <Fragment>
            <Breadcrumb parent="Configuraciones" title="Lista" />
            <div className="container-fluid">
                <div className='row m-b-10'>
                    <div className="offset-sm-6 col-sm-6 text-right">
                        <Link to={`${urlPage}/new`}>
                            <button className="btn btn-primary" type="button"><i className="fa fa-plus-circle"></i> Agregar</button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Configuraciones</h5>
                            </div>
                            {loadData === true ? <Progress animated color="info" value={100} className='sm-progress-bar' /> : ''}
                            {configs.length > 0 ?
                                <React.Fragment>
                                    <div className="table-responsive">

                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Id Configuraciones</th>
                                                    <th scope="col">Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {configs.map((value, index) => {
                                                    return <tr key={index}>
                                                        <td>{value._id}</td>
                                                        <td>{value.email}</td>
                                                        <td>
                                                            <div className='row'>
                                                                <div className='col-sm-3 text-center'>
                                                                    <Link className='h5' to={`${urlPage}/${value._id}`}>
                                                                        <i className="txt-success fa fa-edit"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                                :
                                <div className="card-body" style={(loadData === false && configs.length === 0) ? { display: 'block' } : { display: 'none' }}>
                                    <Alert color="info" className="dark text-center">
                                        <p className="h1">
                                            Sin datos almacenados
                                </p>
                                    </Alert>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ListConfigs;