import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import async from 'async';
import CKEditor from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import UploadImage from '../../helpers/uploadImage';
import "react-datepicker/dist/react-datepicker.css";
import SectionsServices from '../../services/sections.services';
import LanguagesServices from '../../services/languages.services';
import LocalStorageServices from '../../services/localStorage.services';
import Helpers from '../../helpers/helpers';
const moduleName = 'sections';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;


const FormSections = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [image, setImage] = useState(null);
    const [imageInput, setImageInput] = useState('');
    const { register, handleSubmit, errors, setValue, getValues, triggerValidation } = useForm();
    const currentUser = LocalStorageServices.getCurrentUser();

    useEffect(() => {
        const setValuesForm = (data, languages) => {
            setValue('estatus', data.estatus);
            setValue('clave_seccion', data.clave_seccion);
            setImageInput(data.imagen);
            languages.forEach(function (value, index) {
                let languageForm = data.idiomas.find(language => language.id_idioma._id === value._id);
                //let languageForm = data.idiomas.find(language => language.id_idioma === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].titulo`, languageForm.titulo);
                    setValue(`idiomas[${index}].url`, languageForm.url);
                    setValue(`idiomas[${index}].resumen`, languageForm.resumen);
                    setValue(`idiomas[${index}].contenido`, languageForm.contenido);
                    setValue(`idiomas[${index}].seo.titulo`, languageForm.seo.titulo);
                    setValue(`idiomas[${index}].seo.titulo_link`, languageForm.seo.titulo_link);
                    setValue(`idiomas[${index}].seo.palabras_clave`, languageForm.seo.palabras_clave);
                    setValue(`idiomas[${index}].seo.descripcion`, languageForm.seo.descripcion);
                    setValue(`idiomas[${index}].imagen.titulo`, languageForm.imagen.titulo);
                    setValue(`idiomas[${index}].imagen.alt`, languageForm.imagen.alt);
                }
            });
            //set categorias extras

        }
        async function fetchData() {
            setLoadData(true);
            async.series({
                languages: function (cb) {
                    let params = {
                        limit: 10000000,
                        order: -1,
                        sort: "fecha_creacion"
                    }
                    LanguagesServices.get(params)
                        .then(response => {
                            cb(null, response.data.data);
                        })
                        .catch(error => {
                            toast.error("Oops ha ocurrido un error al obtener los idiomas!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            console.log(error);
                            cb(error, null);
                        });
                }
            }, function (error, results) {
                if (error === null) {
                    setLanguages(results.languages);
                    var _languages = results.languages;
                    async.parallel({},
                        async function (error, results) {
                            if (error === null) {
                                if (props.match.params.id !== 'new') {
                                    setTitleForm('Editar');
                                    await SectionsServices.getOne(props.match.params.id)
                                        .then(response => {
                                            console.log(response)
                                            setValuesForm(response.data, _languages);
                                            setLoadData(false);
                                        })
                                        .catch(error => {
                                            setLoadData(false);
                                            toast.error("Oops ha ocurrido un error!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                            console.log(error);
                                        })
                                }
                            }
                            setLoadData(false);
                        });
                }
            });
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {



        data.imagen = image !== null ? image : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        try {
            SectionsServices.create(data)
                .then(response => {
                    toast.success("Categoría guardada correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/portal/sections");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    const update = (data) => {
        if (image !== null) {
            data.imagen = image;
        }
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        SectionsServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Categoría actualizada correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/sections");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }



    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = Helpers.formatUrl(e.target.value);
    }
    const handleImage = (image) => {
        setImage(image)
    }


    const validateAllInputs = async () => {
        const result = await triggerValidation();
        console.log(result);
        if (!result) {
            toast.error("Verifique que haya llenado los campos obligatorios en los idiomas!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Secciones" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Sección</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                                    <div className='form-row'>
                                        <div className="col-md-4 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className="col-md-4 mb-3">
                                            <label>Clave Sección</label>
                                            <input className="form-control" name="clave_seccion" type="text" placeholder="titulo-clave-seccion" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                            <span>{errors.nombre && 'Nombre de la clave-sección  es requerido'}</span>
                                            <span>IMPORTANTE: Una vez guardado este campo no lo modifique</span>
                                        </div>
                                    </div>
                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="myTab" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Título</label>
                                                        <input className="form-control" name={`idiomas[${index}].titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].titulo`] && 'titulo es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>URL</label>
                                                        <input className="form-control" name={`idiomas[${index}].url`} type="text" placeholder="URL" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                                        <span>{errors[`idiomas[${index}].url`] && 'URL es requerido'}</span>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Contenido</label>
                                                        <CKEditor
                                                            editor={Editor}
                                                            data={getValues()[`idiomas[${index}].contenido`]}
                                                            onChange={(event, editor) => {
                                                                setValue(`idiomas[${index}].contenido`, editor.getData())
                                                            }}
                                                        />
                                                        <textarea className='hidden-element' name={`idiomas[${index}].contenido`} ref={register({ required: true })} rows="3"></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>SEO</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo Link</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo_link`} type="text" placeholder="Título Link" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo_link`] && 'Título link es requerido'}</span>
                                                    </div>

                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>Datos de Imagen</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-6 mb-3">
                                                        <label>Título</label>
                                                        <input className="form-control" name={`idiomas[${index}].imagen.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].imagen.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Alt</label>
                                                        <input className="form-control" name={`idiomas[${index}].imagen.alt`} type="text" placeholder="Alt" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].imagen.alt`] && 'Alt es requerido'}</span>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>
                                    <div className='form-row'>
                                        <div className="col-md-12 mb-3">
                                            <UploadImage id={props.match.params.id} imageInput={imageInput} setImage={handleImage} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button onClick={async () => { validateAllInputs() }} className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormSections;