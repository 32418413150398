import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';
import UploadImage from '../../helpers/uploadImage';
import AuthorsServices from '../../services/authors.services';
import LocalStorageServices from '../../services/localStorage.services';
import Helpers from '../../helpers/helpers';
const moduleName = 'authors';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;

const FormAuthors = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [image, setImage] = useState(null);
    const [imageInput, setImageInput] = useState(null);
    const { register, handleSubmit, errors, setValue } = useForm(); // initialise the hook
    const currentUser = LocalStorageServices.getCurrentUser();
    useEffect(() => {
        const setValuesForm = (data) => {
            setValue("nombre", data.nombre);
            setValue("url", data.url);
            setValue("estatus", data.estatus);
            setValue("seo.titulo", data.seo.titulo);
            setValue("seo.titulo_link", data.seo.titulo_link);
            setValue("seo.palabras_clave", data.seo.palabras_clave);
            setValue("seo.descripcion", data.seo.descripcion);
            setImageInput(data.imagen);
        }

        if (props.match.params.id !== 'new') {
            setTitleForm('Editar');
            setLoadData(true);
            AuthorsServices.getOne(props.match.params.id)
                .then(response => {
                    setValuesForm(response.data);
                    setLoadData(false);
                })
                .catch(error => {
                    setLoadData(false);
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                })
        }
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        data.imagen = image !== null ? image : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        AuthorsServices.create(data)
            .then(response => {
                toast.success("Autor guardado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                props.history.push("/portal/authors");
            })
            .catch(error => {
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                /* console.log(error); */
            })
    }
    const update = (data) => {
        if (image !== null) {
            data.imagen = image;
        }
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        AuthorsServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Autor actualizado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/authors");
            })
            .catch(error => {
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                /* console.log(error); */
            })
    }

    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = Helpers.formatUrl(e.target.value);
    }
    const handleImage = (image) => {
        setImage(image)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Autores" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Autor</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label>Nombre</label>
                                            <input className="form-control" name="nombre" type="text" placeholder="Nombre" ref={register({ required: true })} />
                                            <span>{errors.nombre && 'Nombre es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>URL Amigable</label>
                                            <input className="form-control" name="url" type="text" placeholder="ejemplo-de-url-amigable" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                            <span>{errors.url && 'URL es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <h5>SEO</h5>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label>Titulo</label>
                                            <input className="form-control" name="seo.titulo" type="text" placeholder="Título" ref={register({ required: true })} />
                                            <span>{errors['seo.titulo'] && 'Título es requerido'}</span>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label>Titulo Link</label>
                                            <input className="form-control" name="seo.titulo_link" type="text" placeholder="Título Link" ref={register({ required: true })} />
                                            <span>{errors['seo.titulo_link'] && 'Título link es requerido'}</span>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label>Palabras Clave</label>
                                            <input className="form-control" name="seo.palabras_clave" type="text" placeholder="Palabras clave" ref={register({ required: true })} />
                                            <span>{errors['seo.palabras_clave'] && 'Palabras clave es requerido'}</span>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label>Descripción</label>
                                            <textarea className="form-control" name="seo.descripcion" type="text" placeholder="Descripción" ref={register({ required: true })} ></textarea>
                                            <span>{errors['seo.descripcion'] && 'Descripción es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className="col-md-12 mb-3">
                                            <UploadImage id={props.match.params.id} imageInput={imageInput} setImage={handleImage} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormAuthors;