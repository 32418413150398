import React from 'react';
import ImageUploader from "react-images-upload";
import { toast } from 'react-toastify';
import ImgThumbor from './imgThumbor';
import MediasServices from '../services/medias.services';

class UploadImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeImage: true,
            oldImage: null,
        }
        this.onDrop = this.onDrop.bind(this);
    }
    componentDidMount() {
        if (this.props.id !== 'new') {
            this.setState({ changeImage: false });
        }
    }
    onDrop = (image) => {
        var _image = image.length > 0 ? image[0] : null;
        if (_image !== null) {
            const formData = new FormData();
            formData.append('image', _image);
            formData.append('oldImage', this.state.oldImage);
            MediasServices.upload(formData)
                .then(response => {
                    if (response.status === 200) {
                        this.setState({ oldImage: response.data.data.location });
                        this.props.setImage(response.data.data.location);
                    } else {
                        toast.error("Oops ha ocurrido un error al subir la imagen!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
                .catch(error => {
                    toast.error("Oops ha ocurrido un error al subir la imagen!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                })
        } else {
            this.props.setImage(null);
            /* if (this.state.oldImage !== null) {
                MediasServices.delete(this.state.oldImage)
                    .then(response => {
                        if (response.status === 200) {
                            this.props.setImage(null);
                            this.setState({ oldImage: null });
                        } else {
                            toast.error("Oops ha ocurrido un error al eliminar la imagen anterior!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        }
                    })
                    .catch(error => {
                        toast.error("Oops ha ocurrido un error al eliminar la imagen anterior!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    })
            }else{
                this.props.setImage(null);
            } */
        }
    };
    render() {
        return (
            <React.Fragment>
                {this.state.changeImage === true ?
                    <React.Fragment>
                        <ImageUploader
                            withPreview={true}
                            buttonText="Selecciona una imagen"
                            withIcon={true}
                            label="Tamaño máximo 5MB"
                            onChange={this.onDrop}
                            imgExtension={[".jpg", ".gif", ".png", ".gif", ".jpeg"]}
                            maxFileSize={5242880}
                        />
                        {this.props.id !== 'new' &&
                            <div className='row mb-3'>
                                <div className="col-md-12 text-center">
                                    <button className="btn btn-pill btn-secondary btn-sm " onClick={(e) => { e.preventDefault(); this.setState({ changeImage: false }); }} > Cancelar</button>
                                </div>
                            </div>

                        }
                    </React.Fragment>
                    :
                    <div className='row mb-3'>
                        <div className="col-md-12 text-center">
                            <label>Imagen:</label>
                            <div className='mb-3'>
                                <ImgThumbor image={this.props.imageInput} width={250} height={250} />
                            </div>
                            <button className="btn btn-pill btn-secondary btn-sm" onClick={(e) => { e.preventDefault(); this.setState({ changeImage: true }); }} > Modificar</button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default UploadImage;