import API from './api';
//import axios from 'axios';
import Helpers from '../helpers/helpers';
import InterceptorsServices from './interceptors.services'
const moduleAPI = 'cms/flotillas'
/*let API = axios.create({
    baseURL: 'http://localhost:3004',
    timeout: 10000,
    headers: {'X-Custom-Header': 'foobar'}
  }); */
 

const FleetServices = {
    get: async (params) => {
        let query = ''
        if(!Helpers.isEmpty(params)){
            query = Helpers.setQueryParams(params)
        }
        await InterceptorsServices.setToken(API);
        const results = await API.get(moduleAPI + query);
        return results;
    },
    getOne: async (id) => {
        await InterceptorsServices.setToken(API);
        const results = await API.get(moduleAPI + '/' + id);
        return results;
    },
    create: async (data) => {
        await InterceptorsServices.setToken(API);
        const results = await API.post(moduleAPI, data);
        return results;
    },
    update: async (id, data) => {
        await InterceptorsServices.setToken(API);
        const results = await API.patch(moduleAPI + '/' + id, data);
        return results;
    }
}

export default FleetServices;