import { config } from '../config/config'
import React from 'react';
var Thumbor = require('thumbor');
var thumbor = new Thumbor(config.thumbor.secretPass, config.thumbor.url);

const ImgThumbor = (props) => {
    let imagePath = '';
    if (props.image !== '' && props.image !== null) {
        imagePath = thumbor.setImagePath(props.image).resize(props.width, props.height).smartCrop(true).buildUrl();
    }
    return (
        <React.Fragment>
            {props.image !== '' ? <img src={imagePath} className='img-thumbnail' alt='' />
                : ''
            }
        </React.Fragment>

    );
};

export default ImgThumbor;