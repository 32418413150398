import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import async from 'async';
import UploadImage from '../../helpers/uploadImage';
import BannerServices from '../../services/banner.services';
import LocalStorageServices from '../../services/localStorage.services';
import LanguagesServices from '../../services/languages.services';
const moduleName = 'banners';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;

const FormBanners = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [image, setImage] = useState(null);
    const [imageInput, setImageInput] = useState(null);
    const { register, handleSubmit, errors, setValue, triggerValidation } = useForm(); // initialise the hook
    const [languages, setLanguages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const currentUser = LocalStorageServices.getCurrentUser();

    useEffect(() => {
        const setValuesForm = (data, languages) => {
            setValue('estatus', data.estatus);
            setValue('link', data.link);
            setValue('texto_banner', data.texto_banner);
            setValue('title', data.title);
            setImageInput(data.imagen);
            languages.forEach(function (value, index) {

                let languageForm = data.idiomas.find(language => language.id_idioma._id === value._id);
                //let languageForm = data.idiomas.find(language => language.id_idioma === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].texto_banner`, languageForm.texto_banner);
                }
            });

        }

        async function fetchData() {
            setLoadData(true);
            async.series({
                languages: function (cb) {
                    let params = {
                        limit: 10000000,
                        order: -1,
                        sort: "fecha_creacion"
                    }
                    LanguagesServices.get(params)
                        .then(response => {
                            cb(null, response.data.data);
                        })
                        .catch(error => {
                            toast.error("Oops ha ocurrido un error al obtener los idiomas!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            console.log(error);
                            cb(error, null);
                        });
                }
            }, function (error, results) {
                if (error === null) {
                    setLanguages(results.languages);
                    var _languages = results.languages;
                    async.parallel({

                    },
                        async function (error, results) {
                            if (error === null) {
                                if (props.match.params.id !== 'new') {
                                    setTitleForm('Editar');
                                    await BannerServices.getOne(props.match.params.id)
                                        .then(response => {
                                            setValuesForm(response.data, _languages);
                                            setLoadData(false);
                                        })
                                        .catch(error => {
                                            setLoadData(false);
                                            toast.error("Oops ha ocurrido un error!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                            console.log(error);
                                        })
                                }
                            }
                            setLoadData(false);
                        });
                }
            });
        }
        fetchData();

        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        data.imagen = image !== null ? image : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        BannerServices.create(data)
            .then(response => {
                toast.success("Banner guardado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                props.history.push("/portal/banners");
            })
            .catch(error => {
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                /* console.log(error); */
            })
    }
    const update = (data) => {
        if (image !== null) {
            data.imagen = image;
        }
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        BannerServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Banner actualizado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/banners");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }

    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = e.target.value.replace(/ /g, "-");
    }
    const handleImage = (image) => {
        setImage(image)
    }

    const validateAllInputs = async () => {
        const result = await triggerValidation();
        console.log(result);
        if (!result) {
            toast.error("Verifique que haya llenado los campos obligatorios en los idiomas!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Banners" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Banner</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    {/* Imágen del banner */}
                                    <div className='form-row'>
                                        <div className="col-md-8 mb-3">
                                            <UploadImage id={props.match.params.id} imageInput={imageInput} setImage={handleImage} />
                                        </div>
                                    </div>

                                    <div className="card-header m-b-20"></div>
                                    <div className="form-row">
                                        {/* Titulo Banner */}
                                        <div className="col-md-3 mb-3">
                                            <label>Titulo del banner</label>
                                            <input className="form-control" name="title" type="text" ref={register({ required: false })} />
                                        </div>
                                        {/* Link a página */}
                                        <div className="col-md-3 mb-3">
                                            <label>Link del banner</label>
                                            <input className="form-control" name="link" type="text" placeholder="http://supaginaweb.com" ref={register({ required: false })} onKeyUp={handleKeyUp} />
                                        </div>
                                        <div className="col-md-2 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>

                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="myTab" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-8 mb-3">
                                                        <label>Resumen</label>
                                                        <textarea className="form-control" name={`idiomas[${index}].texto_banner`} ref={register({ required: true })} rows="3"></textarea>
                                                        <span>{errors[`idiomas[${index}].texto_banner`] && 'Texto del banner es requerido'}</span>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>

                                    <div className='row'>
                                        <div className='col-sm-8 text-center'>
                                            <button onClick={async () => { validateAllInputs() }} className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormBanners;