const config = {
    thumbor: {
        url: 'https://imagec.rbsistemas.mx',
        secretPass: 'S3_4CC4SS_S3CUR1T1'
    },
    apiMedias: {
        url: 'https://cms.rbsistemas.mx'
    },
    urlAPI:'https://cmsapi.rbsistemas.mx'
}
export default config;
