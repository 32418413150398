import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import CategoriesServices from '../../services/categories.services';
import LanguagesServices from '../../services/languages.services';
import LocalStorageServices from '../../services/localStorage.services';
import Helpers from '../../helpers/helpers';
const moduleName = 'categories';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;

const FormCategories = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const { register, handleSubmit, errors, setValue, triggerValidation } = useForm(); // initialise the hook
    const currentUser = LocalStorageServices.getCurrentUser();
    useEffect(() => {
        const setValuesForm = (data, languages) => {
            setValue('estatus', data.estatus);
            languages.forEach(function (value, index) {
                let languageForm = data.idiomas.find(language => language.id_idioma._id === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].nombre`, languageForm.nombre);
                    setValue(`idiomas[${index}].url`, languageForm.url);
                    setValue(`idiomas[${index}].descripcion`, languageForm.descripcion);
                    setValue(`idiomas[${index}].seo.titulo`, languageForm.seo.titulo);
                    setValue(`idiomas[${index}].seo.titulo_link`, languageForm.seo.titulo_link);
                    setValue(`idiomas[${index}].seo.palabras_clave`, languageForm.seo.palabras_clave);
                    setValue(`idiomas[${index}].seo.descripcion`, languageForm.seo.descripcion);
                }
            })
        }
        async function fetchData() {
            let params = {
                limit: 10000000,
                order: -1,
                sort: "fecha_creacion"
            }
            setLoadData(true);
            let _languages = [];
            await LanguagesServices.get(params)
                .then(response => {
                    setLanguages(response.data.data);
                    _languages = response.data.data;
                })
                .catch(error => {
                    toast.error("Oops ha ocurrido un error al cargar perfiles de usuario!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                });
            if (props.match.params.id !== 'new') {
                setTitleForm('Editar');
                await CategoriesServices.getOne(props.match.params.id)
                    .then(response => {
                        setValuesForm(response.data, _languages);
                        setLoadData(false);
                    })
                    .catch(error => {
                        setLoadData(false);
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log(error);
                    })
            }
            setLoadData(false);
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = (data) => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        try {
            CategoriesServices.create(data)
                .then(response => {
                    toast.success("Categoría guardada correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/portal/categories");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    const update = (data) => {
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        CategoriesServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Categoría actualizada correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/categories");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }

    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = Helpers.formatUrl(e.target.value);
    }

    const validateAllInputs = async () => {
        const result = await triggerValidation();
        console.log(result);
        if (!result) {
            toast.error("Verifique que haya llenado los campos obligatorios en los idiomas!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Categorías" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Categoría</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustomUsername">Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="myTab" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="validationCustom01">Nombre</label>
                                                        <input className="form-control" name={`idiomas[${index}].nombre`} type="text" placeholder="Nombre" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].nombre`] && 'Nombre es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label htmlFor="validationCustom01">URL</label>
                                                        <input className="form-control" name={`idiomas[${index}].url`} type="text" placeholder="URL" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                                        <span>{errors[`idiomas[${index}].url`] && 'URL es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Descripción</label>
                                                        <textarea className="form-control" name={`idiomas[${index}].descripcion`} ref={register({ required: true })} rows="3"></textarea>
                                                        <span>{errors[`idiomas[${index}].descripcion`] && 'Descripción es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>SEO</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom01">Titulo</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom01">Titulo Link</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo_link`} type="text" placeholder="Título Link" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo_link`] && 'Título link es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom01">Palabras Clave</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.palabras_clave`} type="text" placeholder="Palabras clave" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.palabras_clave`] && 'Palabras clave es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor="validationCustom01">Descripción</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.descripcion`} type="text" placeholder="Descripción" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.descripcion`] && 'Descripción es requerido'}</span>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button onClick={async () => { validateAllInputs() }} className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormCategories;