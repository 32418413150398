const LocalStorageServices = {
    setCurrentUser: (data) => {
        let currentUser = {
            id: data.id,
            nombre: data.nombre,
            usuario: data.usuario,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido
        }
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
    },
    getCurrentUser: () => {
        return JSON.parse(localStorage.getItem('currentUser'));
    },
    deleteCurrentUser: () => {
        localStorage.removeItem('currentUser');
    },
    setDataToken: (data) => {
        let create = new Date().getTime();
        let dataToken = {
            jwt: data.jwt,
            rft: data.rft,
            expira: data.expira,
            create: create
        }
        localStorage.setItem('dataToken', JSON.stringify(dataToken));
    },
    deleteAllData: () => {
        localStorage.clear();
    },
    getDataToken: () => {
        let token = JSON.parse(localStorage.getItem('dataToken'))
        return token;
    },
    deleteDataToken: () => {
        localStorage.removeItem('dataToken');
    },

    setIP: (data) => {
        localStorage.setItem('IP', data);
    },
    getIP: () => {
        return localStorage.getItem('IP');
    },
    setLanguageDefault:(data) =>{
        localStorage.setItem('languageDefault', JSON.stringify(data));
    },
    getLanguageDefault:() =>{
        return JSON.parse(localStorage.getItem('languageDefault'));
    },
}

export default LocalStorageServices;