import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import async from 'async';
import { Multiselect } from 'multiselect-react-dropdown';
import DatePicker from "react-datepicker";

import Editor from 'ckeditor5-custom-build/build/ckeditor';
import CKEditor from '@ckeditor/ckeditor5-react';
import moment from 'moment-timezone';
import UploadImage from '../../helpers/uploadImage';
import "react-datepicker/dist/react-datepicker.css";
import NewsServices from '../../services/news.services';
import LanguagesServices from '../../services/languages.services';
import CategoriesServices from '../../services/categories.services';
import AuthorsServices from '../../services/authors.services';
import TagsServices from '../../services/tags.services';
import LocalStorageServices from '../../services/localStorage.services';
import Helpers from '../../helpers/helpers';
import MediasServices from '../../services/medias.services';


import { config } from '../../config/config'
var Thumbor = require('thumbor');
var thumbor = new Thumbor(config.thumbor.secretPass, config.thumbor.url);


const moduleName = 'news';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;
var languageDefault = '';
const FormNews = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [tags, setTags] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [image, setImage] = useState(null);
    const [imageInput, setImageInput] = useState('');
    const { register, handleSubmit, errors, setValue, getValues, triggerValidation } = useForm();
    const currentUser = LocalStorageServices.getCurrentUser();
    const styleSelect = {
        searchBox: {
            border: "1px solid #374558",
            backgroundColor: "#293240",
        },
        inputField: {
            color: 'rgba(255, 255, 255, 0.85)'
        },
        optionContainer: {
            backgroundColor: '#293240'
        },
        chips: {
            backgroundColor: '#4466f2',
            fontSize: '14px'
        }
    }
    useEffect(() => {
        const setValuesForm = (data, languages) => {
            setValue('categoria_principal', data.categoria_principal._id);
            setValue('id_autor', data.id_autor._id);
            setValue('estatus', data.estatus);
            setStartDate(moment(data.fecha_publicacion).toDate());
            setImageInput(data.imagen);
            languages.forEach(function (value, index) {
                let languageForm = data.idiomas.find(language => language.id_idioma._id === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].titulo`, languageForm.titulo);
                    setValue(`idiomas[${index}].url`, languageForm.url);
                    setValue(`idiomas[${index}].resumen`, languageForm.resumen);
                    setValue(`idiomas[${index}].contenido`, languageForm.contenido);
                    setValue(`idiomas[${index}].seo.titulo`, languageForm.seo.titulo);
                    setValue(`idiomas[${index}].seo.titulo_link`, languageForm.seo.titulo_link);
                    setValue(`idiomas[${index}].seo.palabras_clave`, languageForm.seo.palabras_clave);
                    setValue(`idiomas[${index}].seo.descripcion`, languageForm.seo.descripcion);
                    setValue(`idiomas[${index}].imagen.titulo`, languageForm.imagen.titulo);
                    setValue(`idiomas[${index}].imagen.alt`, languageForm.imagen.alt);
                }
            });
            //set categorias extras
            let categories = [];
            data.categorias_extras.forEach(category => {
                let item = {
                    "_id": category._id,
                    "nombre": ''
                }
                let languageCategory = category.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                item.nombre = languageCategory.nombre;
                categories.push(item);
            });
            setSelectedCategories(categories);
            //set etiquetas
            let tags = [];
            data.etiquetas.forEach(tag => {
                let item = {
                    "_id": tag._id,
                    "nombre": ''
                }
                let languageTag = tag.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                item.nombre = languageTag.nombre;
                tags.push(item);
            });
            setSelectedTags(tags);
        }
        async function fetchData() {
            setLoadData(true);
            async.series({
                languages: function (cb) {
                    let params = {
                        limit: 10000000,
                        order: -1,
                        sort: "fecha_creacion"
                    }
                    LanguagesServices.get(params)
                        .then(response => {
                            cb(null, response.data.data);
                        })
                        .catch(error => {
                            toast.error("Oops ha ocurrido un error al obtener los idiomas!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            console.log(error);
                            cb(error, null);
                        });
                }
            }, function (error, results) {
                if (error === null) {
                    setLanguages(results.languages);
                    var _languages = results.languages;
                    languageDefault = results.languages.find(language => language.idioma_defecto === true);
                    async.parallel({
                        categories: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            CategoriesServices.get(params)
                                .then(response => {
                                    let categories = [];
                                    response.data.data.forEach(category => {
                                        let item = {
                                            "_id": category._id,
                                            "nombre": ''
                                        }
                                        let languageCategory = category.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                                        item.nombre = languageCategory.nombre;
                                        categories.push(item);
                                    });
                                    cb(null, categories);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener las categorías!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        },
                        authors: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            AuthorsServices.get(params)
                                .then(response => {
                                    cb(null, response.data.data);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener los autores!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        },
                        tags: function (cb) {
                            let params = {
                                limit: 10000000,
                                order: -1,
                                sort: "fecha_creacion"
                            }
                            TagsServices.get(params)
                                .then(response => {
                                    let tags = [];
                                    response.data.data.forEach(tag => {
                                        let item = {
                                            "_id": tag._id,
                                            "nombre": ''
                                        }
                                        let languageCategory = tag.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                                        item.nombre = languageCategory.nombre;
                                        tags.push(item);
                                    });
                                    cb(null, tags);
                                })
                                .catch(error => {
                                    toast.error("Oops ha ocurrido un error al obtener las etiquetas!", {
                                        position: toast.POSITION.BOTTOM_CENTER
                                    });
                                    console.log(error);
                                    cb(error, null);
                                });
                        }
                    },
                        async function (error, results) {
                            if (error === null) {
                                setCategories(results.categories);
                                setAuthors(results.authors);
                                setTags(results.tags);
                                if (props.match.params.id !== 'new') {
                                    setTitleForm('Editar');
                                    await NewsServices.getOne(props.match.params.id)
                                        .then(response => {
                                            setValuesForm(response.data, _languages);
                                            setLoadData(false);
                                        })
                                        .catch(error => {
                                            setLoadData(false);
                                            toast.error("Oops ha ocurrido un error!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                            console.log(error);
                                        })
                                }
                            }
                            setLoadData(false);
                        });
                }
            });
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        data.categorias_extras = [];
        data.etiquetas = [];
        selectedCategories.forEach((value) => {
            data.categorias_extras.push(value._id);
        });
        selectedTags.forEach((value) => {
            data.etiquetas.push(value._id);
        });
        data.fecha_publicacion = startDate;
        data.imagen = image !== null ? image : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        try {
            NewsServices.create(data)
                .then(response => {
                    toast.success("Categoría guardada correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/portal/news");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    const update = (data) => {
        if (image !== null) {
            data.imagen = image;
        }
        let jsonBitacora = data;
        data.categorias_extras = [];
        data.etiquetas = [];
        selectedCategories.forEach((value) => {
            data.categorias_extras.push(value._id);
        });
        selectedTags.forEach((value) => {
            data.etiquetas.push(value._id);
        });
        data.fecha_publicacion = startDate;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        NewsServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Categoría actualizada correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/news");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }

    const onSelectCategory = (selectedList, selectedItem) => {
        setSelectedCategories(selectedList);
    }

    const onRemoveCategory = (selectedList, removedItem) => {
        setSelectedCategories(selectedList);
    }

    const onSelectTag = (selectedList, selectedItem) => {
        setSelectedTags(selectedList);
    }

    const onRemoveTag = (selectedList, removedItem) => {
        setSelectedTags(selectedList);
    }
    const handleChange = date => {
        setStartDate(date)
    }
    const handleKeyUp = (e) => {
        e.preventDefault();
        e.target.value = Helpers.formatUrl(e.target.value);
    }
    const handleImage = (image) => {
        setImage(image)
    }

    const validateAllInputs = async () => {
        const result = await triggerValidation();
        console.log(result);
        if (!result) {
            toast.error("Verifique que haya llenado los campos obligatorios en los idiomas!", {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("image", file);
                        body.append('oldImage', null);
                        MediasServices.upload(body)
                            .then(response => {
                                let { data } = response.data;
                                let imagePath = thumbor.setImagePath(data.location).resize(data.dimensions.width, data.dimensions.height).smartCrop(true).buildUrl();
                                resolve({
                                    default: `${imagePath}`
                                });
                                console.log(response);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    });
                });
            }
        };
    }
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <Fragment>
            <Breadcrumb parent="Noticias" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Noticia</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label>Categoría Principal</label><br />
                                            <select className="form-control" name="categoria_principal" ref={register({ required: true })}>
                                                <option value="">Seleccione una categoría</option>
                                                {categories.map((value, index) => {
                                                    return <option value={value._id} key={index}>{value.nombre}</option>
                                                })}
                                            </select>
                                            <span>{errors.categoria_principal && 'Categoría principal es requerido'}</span>
                                        </div>
                                        <div className='col-md-6 mb-3'>
                                            <label>Otras categorías</label>
                                            <Multiselect
                                                className="form-control"
                                                options={categories} // Options to display in the dropdown
                                                selectedValues={selectedCategories} // Preselected value to persist in dropdown
                                                onSelect={onSelectCategory} // Function will trigger on select event
                                                onRemove={onRemoveCategory} // Function will trigger on remove event
                                                displayValue="nombre" // Property name to display in the dropdown options
                                                placeholder='Categorías'
                                                style={styleSelect}
                                                emptyRecordMsg='No hay más categorías'
                                                id='categories'
                                            />

                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col-md-6 mb-3'>
                                            <label>Etiquetas</label>
                                            <Multiselect
                                                className="form-control"
                                                options={tags} // Options to display in the dropdown
                                                selectedValues={selectedTags} // Preselected value to persist in dropdown
                                                onSelect={onSelectTag} // Function will trigger on select event
                                                onRemove={onRemoveTag} // Function will trigger on remove event
                                                displayValue="nombre" // Property name to display in the dropdown options
                                                placeholder='Etiquetas'
                                                style={styleSelect}
                                                emptyRecordMsg='No hay más etiquetas'
                                                id='tags'
                                            />

                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Autor</label>
                                            <select className="form-control" name="id_autor" ref={register({ required: true })}>
                                                <option value="">Seleccione un autor</option>
                                                {authors.map((value, index) => {
                                                    return <option value={value._id} key={index}>{value.nombre}</option>
                                                })}
                                            </select>
                                            <span>{errors.id_autor && 'Autor es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className="col-md-6 mb-3">
                                            <label>Fecha de publicación</label>
                                            <DatePicker className="form-control" showPopperArrow={false} selected={startDate} showTimeSelect onChange={handleChange} dateFormat="dd/MM/yyyy hh:mm aa" />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="myTab" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Título</label>
                                                        <input className="form-control" name={`idiomas[${index}].titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].titulo`] && 'titulo es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>URL</label>
                                                        <input className="form-control" name={`idiomas[${index}].url`} type="text" placeholder="URL" ref={register({ required: true })} onKeyUp={handleKeyUp} />
                                                        <span>{errors[`idiomas[${index}].url`] && 'URL es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Resumen</label>
                                                        <textarea className="form-control" name={`idiomas[${index}].resumen`} ref={register({ required: true })} rows="3"></textarea>
                                                        <span>{errors[`idiomas[${index}].resumen`] && 'Resumen es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Contenido</label>
                                                        <CKEditor
                                                            config={{
                                                                extraPlugins: [uploadPlugin],
                                                                mediaEmbed: {
                                                                    previewsInData: true
                                                                }
                                                            }}
                                                            editor={Editor}
                                                            data={getValues()[`idiomas[${index}].contenido`]}
                                                            onChange={(event, editor) => {
                                                                console.log(editor.getData());
                                                                setValue(`idiomas[${index}].contenido`, editor.getData())
                                                                //handleChange(editor.getData());
                                                            }}
                                                        />
                                                        <textarea className='hidden-element' name={`idiomas[${index}].contenido`} ref={register({ required: true })} rows="3"></textarea>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>SEO</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Titulo Link</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.titulo_link`} type="text" placeholder="Título Link" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.titulo_link`] && 'Título link es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Palabras Clave</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.palabras_clave`} type="text" placeholder="Palabras clave" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.palabras_clave`] && 'Palabras clave es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Descripción</label>
                                                        <input className="form-control" name={`idiomas[${index}].seo.descripcion`} type="text" placeholder="Descripción" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].seo.descripcion`] && 'Descripción es requerido'}</span>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-12">
                                                        <h5>Datos de Imagen</h5>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-md-6 mb-3">
                                                        <label>Título</label>
                                                        <input className="form-control" name={`idiomas[${index}].imagen.titulo`} type="text" placeholder="Título" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].imagen.titulo`] && 'Título es requerido'}</span>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Alt</label>
                                                        <input className="form-control" name={`idiomas[${index}].imagen.alt`} type="text" placeholder="Alt" ref={register({ required: true })} />
                                                        <span>{errors[`idiomas[${index}].imagen.alt`] && 'Alt es requerido'}</span>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>
                                    <div className='form-row'>
                                        <div className="col-md-12 mb-3">
                                            <UploadImage id={props.match.params.id} imageInput={imageInput} setImage={handleImage} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button onClick={async () => { validateAllInputs() }} className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormNews;