import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';
import VariablesServices from '../../services/variables.services';
import LocalStorageServices from '../../services/localStorage.services';
const moduleName = 'variables';
const urlPage = process.env.PUBLIC_URL + '/settings/' + moduleName;

const FormVariables = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const { register, handleSubmit, errors, setValue } = useForm(); // initialise the hook
    const currentUser = LocalStorageServices.getCurrentUser();
    useEffect(() => {
        const setValuesForm = (data) => {
            setValue("clave", data.clave);
            setValue("valor", data.valor);
            setValue("descripcion", data.descripcion);
        }

        if (props.match.params.id !== 'new') {
            setTitleForm('Editar');
            setLoadData(true);
            VariablesServices.getOne(props.match.params.id)
                .then(response => {
                    setValuesForm(response.data);
                    setLoadData(false);
                })
                .catch(error => {
                    setLoadData(false);
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                })
            return () => {
                // clean up
            };
        }
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        data.lista_permisos = "TEST";
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        try {
            VariablesServices.create(data)
                .then(response => {
                    toast.success("Variable de sistema guardado correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/settings/variables");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }
    const update = (data) => {
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        VariablesServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Variable de sistema actualizado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/settings/variables");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }

    const formatStringKey = (e) => {
        // eslint-disable-next-line no-unused-expressions
        e.preventDefault;
        let value = e.target.value;
        value = value.toUpperCase();
        value = value.replace(' ', '_');
        setValue('llave', value.toUpperCase());
    }

    return (
        <Fragment>
            <Breadcrumb parent="Variables del Sistema" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Variable de Sistema</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Clave</label>
                                            <input className="form-control" name="clave" onChange={formatStringKey} type="text" placeholder="Clave" ref={register({ required: true })} />
                                            <span>{errors.llave && 'Clave es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Valor</label>
                                            <input className="form-control" name="valor" type="text" placeholder="valor" ref={register({ required: true })} />
                                            <span>{errors.llave && 'Valor es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Descripción</label>
                                            <input className="form-control" name="descripcion" type="text" placeholder="descripcion" ref={register({ required: true })} />
                                            <span>{errors.descripcion && 'Descripcón es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormVariables;