import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, Alert } from 'reactstrap';
import ImgThumbor from '../../helpers/imgThumbor';
import moment from 'moment-timezone';
import NewsServices from '../../services/news.services';
import Pagination from "react-reactstrap-pagination";
import LocalStorageServices from '../../services/localStorage.services';
const urlPage = process.env.PUBLIC_URL + '/portal/news';

const ListNews = () => {
    const [news, setNews] = useState([]);
    const [loadData, setLoadData] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [totalRecords, setTotalRecords] = useState(0);
    const languageDefault = LocalStorageServices.getLanguageDefault();

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const changePageSize = (event) => {
        setCurrentPage(1);
        setPageSize(parseInt(event.target.value));
    }

    useEffect(() => {
        async function fetchData() {
            setLoadData(true);

            let paramsNews = {
                page: currentPage,
                limit: pageSize
            }
            setLoadData(true);
            NewsServices.get(paramsNews)
                .then(response => {
                    console.log(response)
                    setTotalRecords(parseInt(response.data.pagination.total_records));
                    let news = [];
                    response.data.data.forEach(_news => {
                        let item = {
                            "_id": _news._id,
                            "imagen": _news.imagen,
                            "fecha_publicacion": _news.fecha_publicacion,
                            "estatus": _news.estatus,
                            "autor":_news.id_autor,
                            "categoria_principal":{},
                            "etiquetas":[],
                            "idioma": {}
                        }
                        let languageNew = _news.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                        let languageCategoryMain = _news.categoria_principal.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                        let mainCategory = {
                            "_id":_news.categoria_principal._id,
                            "nombre":languageCategoryMain.nombre
                        }
                        item.categoria_principal = mainCategory;
                        item.idioma = languageNew;
                        _news.etiquetas.forEach((tag) =>{
                            let languageTag = tag.idiomas.find(_language => _language.id_idioma._id === languageDefault._id);
                            let _tag = {
                                "_id":tag._id,
                                "nombre": languageTag.nombre
                            }
                            item.etiquetas.push(_tag);
                        })
                        news.push(item);
                    });
                    setNews(news);
                    setLoadData(false);
                })
                .catch(error => {
                    setLoadData(false);
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                });

        }
        fetchData();
        return () => {
            // clean up
        };
    }, [currentPage, languageDefault._id, pageSize]);

    return (
        <Fragment>
            <Breadcrumb parent="Autores" title="Lista" />
            <div className="container-fluid">
                <div className='row m-b-10'>
                    <div className="offset-sm-6 col-sm-6 text-right">
                        <Link to={`${urlPage}/new`}>
                            <button className="btn btn-primary" type="button"><i className="fa fa-plus-circle"></i> Agregar</button>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Noticias</h5>
                            </div>
                            {loadData === true ? <Progress animated color="info" value={100} className='sm-progress-bar' /> : ''}
                            {news.length > 0 ?
                                <React.Fragment>
                                    <div className="table-responsive">

                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Imagen</th>
                                                    <th scope="col">Titulo</th>
                                                    <th scope="col">Categoría</th>
                                                    <th scope="col">Etiquetas</th>
                                                    <th scope="col">Fecha publicación</th>
                                                    <th scope="col">Autor</th>
                                                    <th scope="col">Estatus</th>
                                                    <th scope="col">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {news.map((value, index) => {
                                                    return <tr key={index}>
                                                        <td><ImgThumbor image={value.imagen} width={50} height={50}/></td>
                                                        <td>{value.idioma.titulo}</td>
                                                        <td>{value.categoria_principal.nombre}</td>
                                                        <td>
                                                            {value.etiquetas.map((etiqueta, i) => {
                                                                return <span className="badge badge-primary" key={i}>{etiqueta.nombre}</span>
                                                            })}
                                                        </td>
                                                        <td>{moment.tz(value.fecha_publicacion, "America/Mexico_city").format('DD/MM/YYYY HH:mm')}</td>
                                                        <td>{value.autor.nombre}</td>
                                                        <td>{value.estatus === 'A' ? 'Activo' : 'Inactivo'}</td>
                                                        <td>
                                                            <div className='row'>
                                                                <div className='col-sm-3 text-center'>
                                                                    <Link className='h5' to={`${urlPage}/${value._id}`}>
                                                                        <i className="txt-success fa fa-edit"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='card-footer'>
                                        <div className='row'>
                                            <div className="col-sm-8">
                                                <div className='row'>
                                                    <div className="col-sm-6 offset-sm-4 text-right">
                                                        Resultados por página:
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <select className="form-control digits" defaultValue={pageSize} onChange={changePageSize}>
                                                            <option value={5}>5</option>
                                                            <option value={10}>10</option>
                                                            <option value={15}>15</option>
                                                            <option value={1000000}>Todos</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 text-right">
                                                <Pagination defaultActivePage={currentPage} className='justify-content-end' totalItems={totalRecords} pageSize={pageSize} onSelect={handlePageChange} maxPaginationNumbers={3} firstPageText='<' previousPageText='<<' nextPageText='>' lastPageText='>>' />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                <div className="card-body" style={(loadData === false && news.length === 0) ? { display: 'block' } : { display: 'none' }}>
                                    <Alert color="info" className="dark text-center">
                                        <p className="h1">
                                            Sin datos almacenados
                                        </p>
                                    </Alert>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ListNews;