import Token from '../helpers/token';
import LocalStorageServices from './localStorage.services';
const InterceptorsServices = {
    setToken: async (axios) => {
        await Token.validate();
        await axios.interceptors.request.use((config) => {
            var dataToken = LocalStorageServices.getDataToken();
            config.headers['Authorization'] = 'Bearer ' + dataToken.jwt;
            return config;
        }, (error) => {
            return Promise.reject(error);
        });
    }
}

export default InterceptorsServices;