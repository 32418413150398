import {
    Layout,
    BookOpen,
    Settings,
    Chrome,
    Globe

} from 'react-feather';

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Layout, type: 'link', path: '/dashboard', bookmark: true, active: true
    },
    {
        title: 'Idiomas', icon: Globe, type: 'link', path: '/languages', active: false
    },
    {
        title: 'Configuración', icon: Settings, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/settings/profiles', title: 'Perfiles de usuario', type: 'link' },
            { path: '/settings/users', title: 'Usuarios', type: 'link' },
            { path: '/settings/variables', title: 'Variables de ambiente', type: 'link' }
        ]
    },
    {
        title: 'Portal', icon: Chrome, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/portal/categories', title: 'Categorías', type: 'link' },
            { path: '/portal/tags', title: 'Etiquetas', type: 'link' },
            { path: '/portal/authors', title: 'Autores', type: 'link' },
            { path: '/portal/news', title: 'Noticias', type: 'link' },
            { path: '/portal/places', title: 'Lugares', type: 'link' },
            { path: '/portal/galleries', title: 'Galerías', type: 'link' },
            { path: '/portal/partners', title: 'Socios', type: 'link' },
            { path: '/portal/sections', title: 'Secciones estáticas', type: 'link' },
            { path: '/portal/slider', title: 'Carrusel', type: 'link' },
            { path: '/portal/fleet', title: 'Flotilla', type: 'link' },
            { path: '/portal/configs', title: 'Configuraciones', type: 'link' },
            { path: '/portal/banners', title: 'Banners', type: 'link' },
        ]
    },
    {
        title: 'Manual de Usuario', icon: BookOpen, type: 'link', path: '/manual', active: false
    },
    /* {
        title: 'Dashboard', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [
            { path: '/dashboard/default', title: 'Default', type: 'link' },
            { path: '/dashboard/ecommerce', title: 'E-Commerce', type: 'link' },
            { path: '/dashboard/university', title: 'University', type: 'link' },
            { path: '/dashboard/crypto', title: 'Crypto', type: 'link' },
            { path: '/dashboard/project', title: 'Project', type: 'link' }
        ]
    }, */
    /* {
        title: 'Support Ticket', icon: Headphones, type: 'link', path: '/support-ticket/supportTicket', active: false
    },
    {
        path: '/sample/samplepage', title: 'Sample Page', icon: File, type: 'link', active: false
    }, */
]

