import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Progress, Alert } from 'reactstrap';
import ImgThumbor from '../../helpers/imgThumbor';
import { toast } from 'react-toastify';
import PartnersServices from '../../services/partners.services';
const urlPage = process.env.PUBLIC_URL + '/portal/partners';



const ListPartners = () => {
  const [partners, setPartners] = useState([]);
  const [loadData, setLoadData] = useState(true);
  useEffect(() => {
    async function fetchData() {
        setLoadData(true);

        let paramsPartners = {
            page: 1,
            limit: 20
        }
        setLoadData(true);
        PartnersServices.get(paramsPartners)
            .then(response => {
                let partners = [];
                response.data.data.forEach(_partners => {
                    let item = {
                        "_id": _partners._id,
                        "imagen": _partners.imagen,
                        "socio": _partners.socio,
                        "estatus": _partners.estatus,
                        "link":_partners.link,
                        "alt-title":_partners.alt_title,
                    }
                    //console.log(response)
                    partners.push(item);
                });
                setPartners(partners);
                setLoadData(false);
            })
            .catch(error => {
                setLoadData(false);
                toast.error("Oops ha ocurrido un error!", {
                  position: toast.POSITION.BOTTOM_CENTER
              });
              console.log(error);
            });

    }
    fetchData();
    return () => {
        // clean up
    };
}, []);
  return (
    <Fragment>
    <Breadcrumb parent="Socios" title="Lista" />
    <div className="container-fluid">
        <div className='row m-b-10'>
            <div className="offset-sm-6 col-sm-6 text-right">
                <Link to={`${urlPage}/new`}>
                    <button className="btn btn-primary" type="button"><i className="fa fa-plus-circle"></i> Agregar</button>
                </Link>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-header">
                        <h5>Socios</h5>
                    </div>
                    {loadData === true ? <Progress animated color="info" value={100} className='sm-progress-bar' /> : ''}
                    {partners.length > 0 ?
                        <React.Fragment>
                            <div className="table-responsive">

                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Imagen</th>
                                            <th scope="col">Socio</th>
                                            <th scope="col">Link</th>
                                            <th scope="col">Estatus</th>
                                            <th scope="col">Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partners.map((value, index) => {
                                            return <tr key={index}>
                                                <td><ImgThumbor image={value.imagen} width={50} height={50}/></td>
                                                <td>{value.socio}</td>
                                                <td>{value.link}</td>
                                                <td>{value.estatus === 'A' ? 'Activo' : 'Inactivo'}</td>
                                                <td>
                                                    <div className='row'>
                                                        <div className='col-sm-3 text-center'>
                                                            <Link className='h5' to={`${urlPage}/${value._id}`}>
                                                                <i className="txt-success fa fa-edit"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card-body" style={(loadData === false && partners.length === 0) ? { display: 'block' } : { display: 'none' }}>
                            <Alert color="info" className="dark text-center">
                                <p className="h1">
                                    Sin datos almacenados
                                </p>
                            </Alert>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
</Fragment>
  );
};

export default ListPartners;