import { ADD_LANGUAGE_DEFAULT } from "../constant/actionTypes";


export default function languagesReducer(state = { languageDefault:null }, action) {
    switch (action.type) {
        case ADD_LANGUAGE_DEFAULT:
            return { ...state, languageDefault: action.payload }
        default:
    }
    return state;
}