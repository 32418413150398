/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Link } from 'react-router-dom';

const urlPageUsers = process.env.PUBLIC_URL + '/settings/users';
const urlPageConfigs = process.env.PUBLIC_URL + '/portal/configs';
const urlPageNews = process.env.PUBLIC_URL + '/portal/news';
const urlPagePlaces = process.env.PUBLIC_URL + '/portal/places';

const Default = () => {
    useEffect(() => {

    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Accesos Directos" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="card">
                            <div className="card-header">
                                <Link to={`${urlPageConfigs}`}>
                                    <h5>
                                        Configuraciones
                                    </h5>
                                </Link>
                                <span>Administra las configuración de tu portal</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card">
                            <div className="card-header">
                                <Link to={`${urlPageUsers}`}>
                                    <h5>
                                        USUARIOS
                                    </h5>
                                </Link>
                                <span>Administra a los usuarios del CMS</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card">
                            <div className="card-header">
                                <Link to={`${urlPageNews}`}>
                                    <h5>
                                        Noticias
                                    </h5>
                                </Link>
                                <span>Crea y edita las noticias del portal</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card">
                            <div className="card-header">
                                <Link to={`${urlPagePlaces}`}>
                                    <h5>
                                        Lugares
                                    </h5>
                                </Link>
                                <span>Crear y edita los lugares a conocer del portal</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;