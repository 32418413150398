import React, { Fragment, useEffect, useState } from 'react';
import publicIp from 'public-ip';
import ReactDOM from 'react-dom';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import * as serviceWorker from './serviceWorker';

// ** Import custom components for redux**
import { Provider } from 'react-redux';
import store from './store/index';
import App from "./components/app";

//import app from "./data/base";
//Services
import LocalStorageServices from './services/localStorage.services';

// Import custom Components
//Dashboard
import Default from './components/dashboard/defaultCompo/default';
//Login
import Signin from './auth/signin';
//Idiomas
import ListLanguages from './components/languages/list';
import FormLanguages from './components/languages/form';
//Perfiles de usuario
import ListUsersProfiles from './components/usersProfiles/list';
import FormUsersProfiles from './components/usersProfiles/form';
//Usuarios
import ListUsers from './components/users/list';
import FormUsers from './components/users/form';
//Categorias
import ListCategories from './components/categories/list';
import FormCategories from './components/categories/form';
//Etiquetas
import ListTags from './components/tags/list';
import FormTags from './components/tags/form';
//Autores
import ListAuthors from './components/authors/list';
import FormAuthors from './components/authors/form';
//Noticias
import ListNews from './components/news/list';
import FormNews from './components/news/form';
//Lugares
import ListPlaces from './components/places/list';
import FormPlaces from './components/places/form';
//Flotilla
import ListFleet from './components/fleet/list';
import FormFleet from './components/fleet/form';
//Galerias
import ListGalleries from './components/galleries/list';
import FormGalleries from './components/galleries/form';
//Socios
import ListPartners from './components/partners/list';
import FormPartners from './components/partners/form';

//Configuraciones
import FormConfigs from './components/general-configs/form';
import ListConfigs from './components/general-configs/list';
//Secciones estáticas
import ListSections from './components/static-sections/list';
import FormSections from './components/static-sections/form';
//Carrusel
import ListSlider from './components/slider/list';
import FormSlider from './components/slider/form';
//Banners
import ListBanners from './components/banners/list';
import FormBanners from './components/banners/form';
import ViewManual from './components/manual/view';
//Variables de ambiente
import ListVariables from './components/variables/list';
import FormVariables from './components/variables/form';
//firebase Auth
function Root() {
    /* const abortController = new AbortController(); */
    const [currentUser, setCurrentUser] = useState(LocalStorageServices.getCurrentUser() || 'null');
    useEffect(() => {
        async function fetchData() {
            if (LocalStorageServices.getIP() === null) {
                let ip = await publicIp.v4();
                LocalStorageServices.setIP(ip);
            }
        }
        fetchData();
        const layout = localStorage.getItem('layout_version');
        setCurrentUser(LocalStorageServices.getCurrentUser());
        document.body.classList.add(layout);
        return () => {
            // clean up
        };
    }, []);
    return (
        <div className="App">
            <Provider store={store}>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route path={`${process.env.PUBLIC_URL}/login`} component={Signin} />
                            {currentUser !== 'null' ?
                                <Fragment>
                                    <App>
                                        {/* dashboard menu */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
                                        }} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/dashboard`} component={Default} />

                                        {/* Idiomas */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/languages`} component={ListLanguages} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/languages/:id`} component={FormLanguages} />
                                        {/* Perfiles de usuario */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/profiles`} component={ListUsersProfiles} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/profiles/:id`} component={FormUsersProfiles} />
                                        {/* Usuarios */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/users`} component={ListUsers} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/users/:id`} component={FormUsers} />
                                        {/* Categorias */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/categories`} component={ListCategories} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/categories/:id`} component={FormCategories} />
                                        {/* Etiquetas */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/tags`} component={ListTags} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/tags/:id`} component={FormTags} />
                                        {/* Autores */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/authors`} component={ListAuthors} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/authors/:id`} component={FormAuthors} />
                                        {/* Noticias */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/news`} component={ListNews} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/news/:id`} component={FormNews} />
                                        {/* Lugares */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/places`} component={ListPlaces} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/places/:id`} component={FormPlaces} />
                                        {/* Flotilla */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/fleet`} component={ListFleet} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/fleet/:id`} component={FormFleet} />
                                        {/* Galerias */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/galleries`} component={ListGalleries} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/galleries/:id`} component={FormGalleries} />
                                        {/* Socios */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/partners`} component={ListPartners} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/partners/:id`} component={FormPartners} />
                                        {/* Configs */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/configs/`} component={ListConfigs} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/configs/:id`} component={FormConfigs} />
                                        {/* Secciones estáticas */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/sections`} component={ListSections} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/sections/:id`} component={FormSections} />
                                        {/* Carrusel */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/slider`} component={ListSlider} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/slider/:id`} component={FormSlider} />
                                        {/* Banners */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/banners`} component={ListBanners} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/portal/banners/:id`} component={FormBanners} />
                                        {/**Manual */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/manual`} component={ViewManual} />
                                        {/* Variables de ambiente */}
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/variables`} component={ListVariables} />
                                        <Route exact path={`${process.env.PUBLIC_URL}/settings/variables/:id`} component={FormVariables} />
                                    </App>
                                </Fragment>
                                :
                                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
                            }
                        </Switch>
                    </ScrollContext>
                </BrowserRouter>
            </Provider>
        </div >
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));

serviceWorker.unregister();