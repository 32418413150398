import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';
import LanguagesServices from '../../services/languages.services';
import LocalStorageServices from '../../services/localStorage.services';
const moduleName = 'languages';
const urlPage = process.env.PUBLIC_URL + '/' + moduleName;

const FormLanguages = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const { register, handleSubmit, errors, setValue } = useForm(); // initialise the hook
    const currentUser = LocalStorageServices.getCurrentUser();
    useEffect(() => {
        const setValuesForm = (data) => {
            setValue("descripcion", data.descripcion);
            setValue("codigo", data.codigo);
            setValue("estatus", data.estatus);
            setValue("idioma_defecto", data.idioma_defecto);
        }

        if (props.match.params.id !== 'new') {
            setTitleForm('Editar');
            setLoadData(true);
            LanguagesServices.getOne(props.match.params.id)
                .then(response => {
                    setValuesForm(response.data);
                    setLoadData(false);
                })
                .catch(error => {
                    setLoadData(false);
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                })
            return () => {
                // clean up
            };
        }
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        LanguagesServices.create(data)
            .then(response => {
                toast.success("Idioma guardado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                props.history.push("/languages");
            })
            .catch(error => {
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                /* console.log(error); */
            })
    }
    const update = (data) => {
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        LanguagesServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Idioma actualizado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/languages");
            })
            .catch(error => {
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                /* console.log(error); */
            })
    }

    return (
        <Fragment>
            <Breadcrumb parent="Idiomas" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Idioma</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label>Descripción</label>
                                            <input className="form-control" name="descripcion" type="text" placeholder="Descripción" ref={register({ required: true })} />
                                            <span>{errors.descripcion && 'Descripción es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>Código</label>
                                            <input className="form-control" name="codigo" type="text" placeholder="Código" ref={register({ required: true })} />
                                            <span>{errors.codigo && 'Código es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label>Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="d-block">
                                            <input className="checkbox_animated" id="chk-ani" type="checkbox" name="idioma_defecto" ref={register({ required: false })} />Idioma por defecto
                                        </label>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormLanguages;