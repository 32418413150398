import API from './api';
import InterceptorsServices from './interceptors.services'
const moduleAPI = 'auth'

const AuthServices = {
    login: async (data) => {
        const results = await API.post(moduleAPI, data);
        return results;
    },
    refreshToken: async(data) =>{
        const results = await API.post(moduleAPI + '/token', data);
        return results;
    },
    logout: async () => {
        await InterceptorsServices.setToken(API);
        const results = await API.post(moduleAPI + '/logout');
        return results;
    },
}

export default AuthServices;