import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import async from 'async';
import UploadImage from '../../helpers/uploadImage';
import "react-datepicker/dist/react-datepicker.css";
import ConfigsServices from '../../services/configs.services';
import LanguagesServices from '../../services/languages.services';
import LocalStorageServices from '../../services/localStorage.services';

const moduleName = 'configs';
const urlPage = process.env.PUBLIC_URL + '/portal/' + moduleName;


const FormConfigs = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [logo, setLogo] = useState(null);
    const [favicon, setFavicon] = useState(null);
    const [logoInput, setLogoInput] = useState('');
    const [faviconInput, setFaviconInput] = useState('');
    const { register, handleSubmit, errors, setValue, } = useForm();
    const currentUser = LocalStorageServices.getCurrentUser();
    console.log(logo)

   
    useEffect(() => {
        const setValuesForm = (data, languages) => {
            setValue('page_description', data.page_description);
            setValue('page_title', data.page_title);
            setValue('phone', data.phone);
            setValue('email', data.email);
            setValue('facebook', data.facebook);
            setValue('instagram', data.instagram);
            setValue('linkedin', data.linkedin);
            setValue('whatsapp', data.whatsapp);
            setValue('twitter', data.twitter);
            setValue('youtube', data.youtube);
            setValue('api_read', data.api_read);
            setValue('api_write', data.api_write);
            setLogoInput(data.logo);
            setFaviconInput(data.favicon);
            languages.forEach(function (value, index) {
                let languageForm = data.idiomas.find(language => language.id_idioma._id === value._id);
                //let languageForm = data.idiomas.find(language => language.id_idioma === value._id);
                if (typeof languageForm !== 'undefined') {
                    setValue(`idiomas[${index}].page_title`, languageForm.page_title);
                    setValue(`idiomas[${index}].page_description`, languageForm.page_description);
                    setValue(`idiomas[${index}].about`, languageForm.about);
                    setValue(`idiomas[${index}].copyright`, languageForm.copyright);

                }
            });          
        }
        async function fetchData() {
            setLoadData(true);
            async.series({
                languages: function (cb) {
                    let params = {
                        limit: 10000000,
                        order: -1,
                        sort: "fecha_creacion"
                    }
                    LanguagesServices.get(params)
                        .then(response => {
                            cb(null, response.data.data);
                        })
                        .catch(error => {
                            toast.error("Oops ha ocurrido un error al obtener los idiomas!", {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            console.log(error);
                            cb(error, null);
                        });
                }
            }, function (error, results) {
                if (error === null) {
                    setLanguages(results.languages);
                    var _languages = results.languages;
                    
                        
                            
                                if (props.match.params.id !== 'new') {
                                    setTitleForm('Editar');
                                     ConfigsServices.getOne(props.match.params.id)
                                        .then(response => {
                                            console.log(response)
                                            setValuesForm(response.data, _languages);
                                            setLogo(response.data.logo)
                                            setFavicon(response.data.favicon)
                                            setLoadData(false);
                                        })
                                        .catch(error => {
                                            setLoadData(false);
                                            toast.error("Oops ha ocurrido un error!", {
                                                position: toast.POSITION.BOTTOM_CENTER
                                            });
                                            console.log(error);
                                        })
                                }
                            
                            setLoadData(false);
                        
                }
            });
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            console.log(props.match.params.id)
            if (props.match.params.id !== 'new') {
                //Actualizar
                console.log(props.match.params.id)
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        
        data.logo = logo !== null ? logo : '/no-imagen.jpg';
        data.favicon = favicon !== null ? favicon : '/no-imagen.jpg';
        let jsonBitacora = JSON.stringify(data);
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        
            ConfigsServices.create(data)
                .then(response => {
                    toast.success("Configuraciones guardadas correctamente!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    props.history.push("/portal/configs");
                })
                .catch(error => {
                    /* console.log(error.response); */
                    setSavingData(false);
                    if (error !== null && error.response.status === 400) {
                        toast.warn(error.response.data.msg, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                })
       
    }
    const update = (data) => {
        if (logo !== null) {
            data.logo = logo;
        } 

        if (favicon !== null) {
            data.favicon = favicon;
        } 
       
        let jsonBitacora = data;
        jsonBitacora.id = props.match.params.id;
        data.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }
        ConfigsServices.update(props.match.params.id, data)
            .then(response => {
                toast.success("Categoría actualizada correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/portal/configs");
            })
            .catch(error => {
                if (error !== null && error.response.status === 400) {
                    toast.warn(error.response.data.msg, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
                setSavingData(false);
                /* console.log(error); */
            })
    }


    

    const handleImageLogo = (logo) => {
        setLogo(logo)
        console.log(logo)
    }

    const handleImageFavicon = (favicon) => {
        setFavicon(favicon)
    }

    return (
        <Fragment>
            <Breadcrumb parent="Configuraciones generales" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Configuraciones Generales</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <h5>HEADER</h5>
                                        </div>
                                        <div className="card col-md-6">
                                            <UploadImage  id={props.match.params.id} imageInput={logoInput} setImage={handleImageLogo} />
                                            <div className="card-body">
                                                <h5 className="card-title">Logo</h5>
                                                <p className="card-text">Cambie la imágen de su logotipo. Tamaño recomendado: 208px X 62px</p>
                                            </div>
                                        </div>
                                        <div className="card col-md-6">
                                            <UploadImage  id={props.match.params.id} imageInput={faviconInput} setImage={handleImageFavicon} />
                                            <div className="card-body">
                                                <h5 className="card-title">Favicon</h5>
                                                <p className="card-text">Cambie la imágen de su favicon. Tamaño recomendado: 32px X 32px</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="form-row">
                                        <div className="col-md-6 mb-3">
                                            <label>Teléfono</label>
                                            <input className="form-control" name="phone" type="text" placeholder="Teléfono de Contacto" ref={register({ required: false })} />
                                            
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label>Email</label>
                                            <input className="form-control" name="email" type="text" placeholder="miemail@mail.com" ref={register({ required: false })} />
                                        
                                        </div>
                                    </div>
                                    <br />
                                    <hr />
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <h5>REDES SOCIALES</h5>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-social-facebook m-r-5"></i><label>Facebook</label>
                                            <input className="form-control" name="facebook" type="text" placeholder="https://facebook.com/facebook" ref={register({ required: false })} />
                                            
                                       </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-social-twitter m-r-5"></i><label>Twitter</label>
                                            <input className="form-control" name="twitter" type="text" placeholder="https://twitter.com/twitter" ref={register({ required: false })} />
                                            
                                        </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-social-instagram m-r-5"></i><label>Instagram</label>
                                            <input className="form-control" name="instagram" type="text" placeholder="https://instagram.com/instagram" ref={register({ required: false })} />
                                            
                                       </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-brand-linkedin m-r-5"></i><label>LinkedIn</label>
                                            <input className="form-control" name="linkedin" type="text" placeholder="https://linkedin.com/linkedin" ref={register({ required: false })} />
                                            
                                        </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-brand-youtube m-r-5"></i><label>YouTube</label>
                                            <input className="form-control" name="youtube" type="text" placeholder="https://youtube.com/youtube" ref={register({ required: false })} />
                                            
                                       </div>
                                        <div className="col-md-6 mb-3">
                                        <i className="icofont icofont-brand-whatsapp m-r-5"></i><label>Whatsapp</label>
                                            <input className="form-control" name="whatsapp" type="text" placeholder="https://api.whatsapp.com/send?phone=529991010101" ref={register({ required: false })} />
                                            
                                        </div>
                                    </div>
                                    <br/>
                                    <hr/>
                                    <div className="col-md-12">
                                        <h5>SEO</h5>
                                    </div>
                                    <Nav tabs className="border-tab-primary">
                                        {languages.map((value, index) => {
                                            return <NavItem className="nav nav-tabs" id="myTab" role="tablist" key={index}>
                                                <NavLink className={activeTab === index ? 'active' : ''} onClick={() => setActiveTab(index)}>
                                                    {value.descripcion}
                                                </NavLink>
                                            </NavItem>
                                        })}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {languages.map((value, index) => {
                                            return <TabPane tabId={index} key={index} className='m-t-15'>
                                                
                                                <div className="form-row">
                                                    <div style={{ display: 'none' }}>
                                                        <input name={`idiomas[${index}].id_idioma`} type="hidden" defaultValue={value._id} ref={register({ required: true })} />
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Page Title</label>
                                                        <textarea className='form-control' name={`idiomas[${index}].page_title`} ref={register({ required: false })} rows="3"></textarea>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Page Description</label>
                                                        <textarea className='form-control' name={`idiomas[${index}].page_description`} ref={register({ required: false })} rows="3"></textarea>
                                                    </div>
                                                    <div className="col-md-12 mb-3">
                                                        <label>Texto Footer 'Acerca de'</label>
                                                        <textarea className='form-control' name={`idiomas[${index}].about`} ref={register({ required: false })} rows="3"></textarea>
                                                    </div>
                                                </div>
                                                
                                                <div className="form-row">
                                                    <div className="col-md-12 mb-3">
                                                        <label>Texto Footer Copyright</label>
                                                        <input className="form-control" name={`idiomas[${index}].copyright`} type="text" placeholder="Título" ref={register({ required: false })} />
                                                        
                                                    </div>
                                                </div>
                                            </TabPane>
                                        })}
                                    </TabContent>
                                    <br/>
                                    <hr />
                                    <div classname='form-row'>
                                        <div className="col-md-12">
                                            <h5>API KEY</h5>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                        <i className="icofont icofont-key m-r-5"></i><label>Read</label>
                                            <input className="form-control" name="api_read" type="text" placeholder="Introduzca su clave Read" ref={register({ required: false })} />
                                        </div>
                                        <div className="col-md-12 mb-3">
                                        <i className="icofont icofont-key m-r-5"></i><label>Write</label>
                                            <input className="form-control" name="api_write" type="text" placeholder="Introduzca su clave Write" ref={register({ required: false })} />
                                        </div>
                                    </div>
                                    <br />
                                    <hr />
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormConfigs;