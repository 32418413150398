import React, { useEffect, Fragment, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import useForm from 'react-hook-form'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Progress } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import PasswordStrengthBar from 'react-password-strength-bar';
import UsersServices from '../../services/users.services';
import UsersProfilesServices from '../../services/usersProfiles.services';
import LocalStorageServices from '../../services/localStorage.services';
const moduleName = 'users';
const urlPage = process.env.PUBLIC_URL + '/settings/' + moduleName;

const MessageErrors = (props) => (
    <ul>
        {props.errors.map((value, index) => {
            return <li key={index}><i className="fa fa-angle-double-right txt-primary m-r-10"></i> {value.msg}</li>
        })}
    </ul>
)

const FormUsers = (props) => {
    const [titleForm, setTitleForm] = useState('Agregar');
    const [savingData, setSavingData] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const { register, handleSubmit, errors, setValue } = useForm(); // initialise the hook
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [inputPassValue, setInputPassValue] = useState('');
    const currentUser = LocalStorageServices.getCurrentUser();
    const styleSelect = {
        searchBox: {
            border: "1px solid #374558",
            backgroundColor: "#293240",
        },
        inputField: {
            color: 'rgba(255, 255, 255, 0.85)'
        },
        optionContainer: {
            backgroundColor: '#293240'
        },
        chips: {
            backgroundColor: '#4466f2',
            fontSize: '14px'
        }
    }
    useEffect(() => {
        const setValuesForm = (data) => {
            setValue("nombre", data.nombre);
            setValue("primer_apellido", data.primer_apellido);
            setValue("segundo_apellido", data.segundo_apellido);
            setValue("usuario", data.usuario);
            setValue("estatus", data.estatus);
        }
        async function fetchData() {
            let params = {
                limit: 10000000,
                order: -1,
                sort: "fecha_creacion"
            }
            setLoadData(true);
            await UsersProfilesServices.get(params)
                .then(response => {
                    setProfiles(response.data.data);
                })
                .catch(error => {
                    toast.error("Oops ha ocurrido un error al cargar perfiles de usuario!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                    console.log(error);
                })
            if (props.match.params.id !== 'new') {
                setTitleForm('Editar');
                await UsersServices.getOne(props.match.params.id)
                    .then(response => {
                        setValuesForm(response.data);
                        setSelectedProfiles(response.data.lista_perfiles);
                    })
                    .catch(error => {
                        toast.error("Oops ha ocurrido un error!", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        console.log(error);
                    })
            }
            setLoadData(false);
        }
        fetchData();
        return () => {
            // clean up
        };
    }, [props.match.params.id, setValue]);

    const onSubmit = data => {
        if (data !== '') {
            setSavingData(true);
            if (props.match.params.id !== 'new') {
                //Actualizar
                update(data);
            } else {
                //Agregar
                create(data);
            }
        } else {
            errors.showMessages();
        }
    };
    const create = (data) => {
        let form = {
            nombre: data.nombre,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            usuario: data.usuario,
            contrasenia: data.contrasenia,
            drowssap: data.contrasenia,
            lista_perfiles: [],
            estatus: data.estatus
        }
        selectedProfiles.forEach(profile => {
            form.lista_perfiles.push(profile._id);
        });
        let jsonBitacora = JSON.stringify(form);
        form.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'create',
            json: jsonBitacora
        }
        UsersServices.create(form)
            .then(response => {
                toast.success("Usuario guardado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                props.history.push("/settings/users");
            })
            .catch(error => {
                // console.log(error.response);
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    if (typeof error.response.data.errores !== 'undefined') {
                        toast.warn(<MessageErrors errors={error.response.data.errores} />, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    } else {
                        toast.warn('El usuario ya esta en uso, intento con otro!', {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    }
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            })
    }
    const update = (data) => {
        let form = {
            nombre: data.nombre,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            usuario: data.usuario,
            lista_perfiles: selectedProfiles,
            estatus: data.estatus
        }
        if (data.contrasenia !== '') {
            form.contrasenia = data.contrasenia;
            form.drowssap = data.contrasenia;
        }

        let jsonBitacora = form;
        jsonBitacora.id = props.match.params.id;
        form.bitacora = {
            ip: LocalStorageServices.getIP(),
            user: currentUser.usuario,
            nombre: currentUser.nombre + ' ' + currentUser.primer_apellido + ' ' + currentUser.segundo_apellido,
            modulo: moduleName,
            accion: 'update',
            json: JSON.stringify(jsonBitacora)
        }

        UsersServices.update(props.match.params.id, form)
            .then(response => {
                toast.success("Usuario actualizado correctamente!", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                props.history.push("/settings/users");
            })
            .catch(error => {
                // console.log(error.response);
                setSavingData(false);
                if (error !== null && error.response.status === 400) {
                    toast.warn(<MessageErrors errors={error.response.data.errores} />, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("Oops ha ocurrido un error!", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                }
            })
    }

    const changeInputPassword = () => {
        let iconView = document.getElementById("field-icon");
        let inputPassword = document.getElementById('_password');
        if (inputPassword.type === 'password') {
            iconView.classList.remove('fa-eye');
            iconView.classList.add('fa-eye-slash');
            inputPassword.type = 'text';
        } else {
            iconView.classList.remove('fa-eye-slash');
            iconView.classList.add('fa-eye');
            inputPassword.type = 'password';
        }
    }

    const onSelect = (selectedList, selectedItem) => {
        setSelectedProfiles(selectedList);
    }

    const onRemove = (selectedList, removedItem) => {
        setSelectedProfiles(selectedList);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Usuarios" title={titleForm} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>{titleForm} Usuario</h5>
                            </div>
                            {loadData === true ? <Progress className='sm-progress-bar' animated color="info" value={100} /> : ''}
                            <div className="card-body">
                                <form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-row">
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Nombre</label>
                                            <input className="form-control" name="nombre" type="text" placeholder="Nombre" ref={register({ required: true })} />
                                            <span>{errors.nombre && 'Nombre es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Primer apellido</label>
                                            <input className="form-control" name="primer_apellido" type="text" placeholder="Primer apellido" ref={register({ required: true })} />
                                            <span>{errors.primer_apellido && 'Primer apellido es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Segundo apellido</label>
                                            <input className="form-control" name="segundo_apellido" type="text" placeholder="Segundo apellido" ref={register({ required: true })} />
                                            <span>{errors.segundo_apellido && 'Segundo apellido es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Usuario</label>
                                            <input className="form-control" name="usuario" type="text" placeholder="Usuario" ref={register({ required: true })} />
                                            <span>{errors.usuario && 'Usuario es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustom01">Contraseña</label>
                                            <input className="form-control p-l-5" name="contrasenia" type="password" placeholder="Contraseña" id='_password' ref={register({ required: titleForm === 'Agregar' ? true : false })} onChange={e => { setInputPassValue(e.target.value) }} />
                                            <i className="fa fa-eye fa-lg field-icon" id="field-icon" onClick={changeInputPassword}></i>
                                            <PasswordStrengthBar password={inputPassValue} minLength={6} shortScoreWord='Demasiado corto' scoreWords={['Muy débil', 'Débil', 'Bien', 'Buena', 'Fuerte']} />
                                            <span>{errors.contrasenia && 'Contraseña es requerido'}</span>
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label htmlFor="validationCustomUsername">Estatus</label>
                                            <select className="form-control" name="estatus" ref={register({ required: true })}>
                                                <option value="">Estatus</option>
                                                <option value="A">Activo</option>
                                                <option value="I">Inactivo</option>
                                            </select>
                                            <span>{errors.estatus && 'Estatus es requerido'}</span>
                                        </div>
                                    </div>
                                    <div className='form-row'>
                                        <div className='col-md-6 mb-3'>
                                            <label htmlFor="validationCustomUsername">Perfiles</label>
                                            <Multiselect
                                                className="form-control"
                                                options={profiles} // Options to display in the dropdown
                                                selectedValues={selectedProfiles} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="nombre" // Property name to display in the dropdown options
                                                placeholder='Perfiles'
                                                style={styleSelect}
                                                emptyRecordMsg='No hay más perfiles'
                                            />

                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 text-center'>
                                            <button className="btn btn-primary m-r-10" type="submit" disabled={savingData}>
                                                {savingData === false ?
                                                    <React.Fragment><i className='fa fa-save'></i> Guardar</React.Fragment>
                                                    :
                                                    <React.Fragment><i className="fa fa-circle-o-notch fa-spin"></i> Guardando</React.Fragment>
                                                }
                                            </button>
                                            <Link to={`${urlPage}`}>
                                                <button className="btn btn-primary" type="submit"><i className="fa fa-arrow-circle-o-left"></i> Cancelar</button>
                                            </Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default FormUsers;